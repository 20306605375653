.title-menu {
  margin: 7rem auto;
  font-size: 2rem;
}
.menu-container{
  width: 1150px;
  margin: 0 auto ;
}
.container {
  display: flex;
  justify-content: space-around;
  margin-top: 3rem;
}

.left {
  margin-right: 1rem;
}
.menu-img{
    height: 700px;
    width: 550px;
    margin-top: 50px;
}

.right {
  margin-left: 1rem;
}

@media screen and (max-width: 960px) {
  .title-menu {
    margin-top: 6rem;
  }
  .menu-container{
    width: 575px;
    margin: 0 auto ;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 0rem;
  }

  .left {
    margin-right: 0rem;
    margin-top: 3rem;
    /* width: 575px;
    background-color: lightcoral; */

  }
  
  .right {
    margin-left: 0rem;
    margin-top: 3rem;
    /* width: 575px;
    background-color: aqua; */

  }
  
}

@media screen and (max-width: 768px) {
  .title-menu {
    margin: 6rem auto 2rem ;
  }
  .menu-container{
    width: 575px;
    margin: 0 auto ;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 0rem;
  }

  .left {
    margin-right: 0rem;
    margin-top: 3rem;
    /* width: 575px;
    background-color: lightcoral; */

  }
  
  .right {
    margin-left: 0rem;
    margin-top: 3rem;
    /* width: 575px;
    background-color: aqua; */

  }
  
}


@media screen and (max-width: 480px) {
  .title-menu {
    margin-top: 6rem;
  }
  .menu-container{
    width: 280px;
    margin: 0 auto ;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 0rem;
  }

  .left {
    margin-right: 0rem;
    margin-top: 3rem;
    /* width: 575px;
    background-color: lightcoral; */

  }
  
  .right {
    margin-left: 0rem;
    margin-top: 3rem;
    /* width: 575px;
    background-color: aqua; */

  }
  
}

@media screen and (max-width: 960px) {
  .left img{
      height: 650px;
      width: 548px;
  }

  .right img{
      height: 650px;
      width: 548px;
  }
}

@media screen and (max-width: 768px) {
  .left img {
    height: 550px;
    width: 480px;
  }

  .right img{
      height: 550px;
      width: 480px;
  }

}

@media screen and (max-width: 480px) {
  .left img {
    height: 475px;
    width: 280px;
  }

  .right img{
      height: 475px;
    width: 280px;
  }
}