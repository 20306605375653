* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
  font-family: "Poppins", sans-serif;
}

a {
  text-decoration: none;
}

ul {
  list-style: none;
}

.App {
  font-family: sans-serif;
  text-align: center;
}

/* .nav-fixed {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
} */
