.title-about {
  margin: 7rem 0 3rem;
  font-size: 2rem;
}

.article-container {
  text-align: left;
  margin: 2rem auto 5rem;
  width: 86vw;
}

article p {
  margin: 1rem 1rem;
}

@media screen and (max-width: 960px) {
  #title {
    margin-top: 6rem;
  }
}
