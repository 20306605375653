.hero {
  height: 100vh;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  margin-top: 4rem;
  background-size: cover;
}

text-svg{ 
  width:583;
  height:274;
}

.svg-text-mb{
  display: none ;
}

@media screen and (max-width: 480px) {

  .hero{
    height: 60vh;
  }


  .svg-text{
    display: none;
  }

  .svg-text-mb{
    display: block;
  }
}