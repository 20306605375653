footer {
  height: 60vh;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 3rem 0;
  text-decoration: none;
}

.info-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.footer-content {
  margin: 2rem;
  line-height: 1.5;
  /* height: 6rem; */
}

.mb-width{
  width: 100%;
  height: 200px;
}

.btn {
  color: black;
  text-align: center;
  font-weight: 600;
  font-size: 20px;
}

.icon-wrapper {
  display: flex;
  justify-content: space-around;
  margin-bottom: 1rem;
}

.rights{
  padding-top: 45px;
  font-size: 13px;
}

a {
  color: black;
}


.socials {
  display: flex;
  padding-top: 35px;
}

.socials a{
  margin-left: 8px;
}

@media screen and (max-width: 960px) {
  footer {
    height: 60vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 3rem 0;
    text-decoration: none;
  }


  .info-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  };


  .btn {
    color: black;
    text-align: center;
    font-weight: 600;
    font-size: 30px ;
  }

  .home{
    padding-top: 40px;
  }
  .contact{
    padding-top: 40px;
  }


  .footer-content p {
    font-size: 18px;
  }

  .footer-content{
    margin: 1rem 2rem;
  }



  .rights{
    padding-top: 30px;
    font-size: 10px;
  }


  .socials {
    /* display: flex; */
    padding-top: 20px;
    /* height: 5px;
    width: 5px; */
  }

}