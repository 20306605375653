.title {
  margin: 7rem 0 1rem;
  font-size: 2rem;
}

#title2 {
  margin: 3rem 0;
  font-size: 1.5rem;
}

.article-container {
  text-align: left;
  margin: 2rem auto 5rem;
  width: 86vw;
}

article p {
  margin: 2rem 1rem;
}

.table-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 3rem;
}

@media screen and (max-width: 960px) {
  #title {
    margin-top: 6rem ;
  }
  #title2 {
    margin: 1rem 0;
    font-size: 1.5rem;
  }
}

@media screen and (max-width: 480px){
.title-menu {
    margin-top: 6rem !important;
}
}