.time-table{

    display: flex;
    justify-content: space-between;
    padding: 0 10%;
    margin-top: 30px;
}
.iftaar-menu{

    display: flex;
    justify-content: space-between;
    padding: 0 10%;
    margin-top: 30px;
}

.time-table img{
    height: 700px;
    width: 550px;
}

.iftaar-menu img{
    height: 700px;
    width: 550px;
}

@media screen and (max-width: 960px) {
    .time-table{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 0 10%;
        margin: 30px auto 0;
    }
    .time-table img{
        height: 650px;
        width: 548px;
    }

    .iftaar-menu img{
        height: 650px;
        width: 548px;
    }
}

@media screen and (max-width: 768px) {
    .time-table img {
      height: 550px;
      width: 480px;
    }

    .iftaar-menu img{
        height: 550px;
        width: 480px;
    }

}

@media screen and (max-width: 480px) {
    .time-table img {
      height: 475px;
      width: 280px;
    }

    .iftaar-menu img{
        height: 475px;
      width: 280px;
    }
}