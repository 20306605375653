#border {
  border-top: 3px solid #f09f45;
  border-bottom: 3px solid #f09f45;
  margin: 8rem 0;
}

.image_wrapper {
  margin: 4rem 3rem;
  overflow: hidden;
}

.image_wrapper img {
  width: 100%;
}

.img-size {
  height: 40.882800608828006vh;
  width: 38.565153733528554vw;
}


@media screen and (max-width: 960px) {
  .img-size {
    height: 20.882800608828006vh;
    width: 18.565153733528554vw;
  }
}