.time-container {
  background-color: #efefef;
  border: 1.7px solid #f09f45;
  height: 400px;
  width: 1140px;
  padding-bottom: 1rem;
  /* align-self: auto; */
  /* margin-right: 5rem;
  margin-bottom: 10rem; */
  margin-left: 1rem ;
}

.time-container h3 {
  font-size: 23px;
  margin-top: 2rem;
}

.time-container p {
  padding: 1rem 0 0;
  font-size: 17px;
}

.menu-wrapper {
  display: flex;
  justify-content: space-between;
  margin: 0 2rem;
  align-items: center;
  list-style: none;
  line-height: 1.9;
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-size: 14px;
}

.item-price {
  display: flex;
  justify-content: space-between;
  margin: 0 2rem 1rem;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
}

.content-wrapper {
  display: flex;
  justify-content: space-around;
}

.setmenu li {
  margin: 2rem 0;
}

.setmenu li:first-child {
  margin: 1rem 0 !important;
}

.setmenu2 li {
  margin: 5rem 0;
}

.setmenu2 li:first-child {
  margin: 1rem 0 !important;
}


@media screen and (max-width: 960px) {

  .time-container {
    background-color: #efefef;
    border: 1.7px solid #f09f45;
    height: 600px;
    width: 550px;
    padding-bottom: 1rem;
    margin: auto;

    /* align-self: auto; */
    /* margin-right: 5rem;
    margin-bottom: 10rem; */
  }

  .table-row{
    font-size: 9px;
  }
  
}

@media screen and (max-width: 768px) {

  .time-container {
    background-color: #efefef;
    border: 1.7px solid #f09f45;
    height: 550px;
    width: 480px;
    padding-bottom: 1rem;
    margin: auto;

    /* align-self: auto; */
    /* margin-right: 5rem;
    margin-bottom: 10rem; */
  }
  
  .time-container p {
    padding: 1rem 0 0;
    font-size: 12px;
  }
}

@media screen and (max-width: 480px) {

  .time-container {
    background-color: #efefef;
    border: 1.7px solid #f09f45;
    height: 400px;
    width: 280px;
    padding-bottom: 1rem;
    margin: auto;

    /* align-self: auto; */
    /* margin-right: 5rem;
    margin-bottom: 10rem; */
  }
  
  .time-container p {
    padding: 1rem 0 0;
    font-size: 9px;
  }
  
}


