.table-row {
  display: flex;
  justify-content: space-between;
  width: 90%;
  margin-left: 25px;
  background: transparent;
}
.table-head {
  background: #8665f7;
  box-shadow: none;
  color: #fff;
  font-weight: 600;
}
.table-head .table-cell {
  border-right: none;
}
.table-cell {
  display: table-cell;
  width: 50%;

  /* border-right: 1px solid #d6d4d4; */
  vertical-align: middle;
}
.table-cell p {
  line-height: 1.5;
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-size: 14px;
  text-decoration: none;
}

.first-cell {
  text-align: left;
  margin-left: 10px;
}
.last-cell {
  border-right: none;
  text-align: right;
}

.first-cell-time p {
  text-align: center;
  padding-left: 10rem;
}
.last-cell-time p {
  border-right: none;
  text-align: center;
  padding-right: 10rem;
}

.middle-cell {
  border-right: none;
  text-align: center;
}

a {
  text-decoration: none;
  color: #555;
}

@media only screen and (max-width:960px) {
  .table-row {
    font-size: 11px;
  }

  .table-cell p {
    line-height: 1.5;
    font-family: "Poppins", sans-serif;
    font-size: 13px ;
    text-decoration: none;
  }

  .table-cell.first-cell p{
    font-size: 9px;
  }
}



@media only screen and (max-width:768px) {
  .table-row {
    font-size: 11px;
  }

  .table-cell p {
    line-height: 1.5;
    font-family: "Poppins", sans-serif;
    font-size: 11px ;
    text-decoration: none;
  }
}
