.card-container {
  background-color: #efefef;
  border: 1.7px solid #f09f45;
  height: 700px;
  width: 550px;
  padding-bottom: 1rem;
}

.card-container h3 {
  font-size: 23px;
  margin-top: 2rem;
  font-family: "Aboreto", cursive;
}

.card-container p {
  text-decoration: underline;
  padding: 1rem 0 0;
  font-family: "Aboreto", cursive;
  font-size: 17px;
}

.menu-wrapper {
  display: flex;
  justify-content: space-between;
  margin: 0 2rem;
  align-items: center;
  list-style: none;
  line-height: 1.9;
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-size: 14px;
}

.item-price {
  display: flex;
  justify-content: space-between;
  margin: 0 2rem 1rem;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
}

.content-wrapper {
  display: flex;
  justify-content: space-around;
}

.list-bullet {
  list-style: inside;
  text-align: left;
  margin: 2rem;
  line-height: 1.5;
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-size: 14px;
}


@media screen and (max-width: 960px) {
  .card-container {
    background-color: #efefef;
    border: 1.7px solid #f09f45;
    height: 650px;
    width: 548px;
    padding-bottom: 1rem;
  }

  .card-container h3 {
    font-size: 16px;
    margin-top: 2rem;
    font-family: "Aboreto", cursive;
  }

  .card-container p {
    text-decoration: underline;
    padding: 1rem 0 0;
    font-family: "Aboreto", cursive;
    font-size: 13px;
  }
  
  .menu-wrapper {
    display: flex;
    justify-content: space-between;
    margin: 0 2rem;
    align-items: center;
    list-style: none;
    line-height: 1.9;
    font-family: "Poppins", sans-serif;
    font-weight: 300;
    font-size: 12px !important;
  }

  .item-price {
    display: flex;
    justify-content: space-between;
    margin: 0 2rem 1rem;
    font-weight: 400;
    font-family: "Poppins", sans-serif;
    font-size: 12px;

  }

  .list-bullet {
    list-style: inside;
    text-align: left;
    margin: 2rem;
    line-height: 1.5;
    font-family: "Poppins", sans-serif;
    font-weight: 300;
    font-size: 9px;
  }
  
}



@media screen and (max-width: 768px) {
  .card-container {
    background-color: #efefef;
    border: 1.7px solid #f09f45;
    height: 550px;
    width: 480px;
    padding-bottom: 1rem;
  }

  .card-container h3 {
    font-size: 16px;
    margin-top: 2rem;
    font-family: "Aboreto", cursive;
  }

  .card-container p {
    text-decoration: underline;
    padding: 1rem 0 0;
    font-family: "Aboreto", cursive;
    font-size: 13px;
  }
  
  .menu-wrapper {
    display: flex;
    justify-content: space-between;
    margin: 0 2rem;
    align-items: center;
    list-style: none;
    line-height: 1.9;
    font-family: "Poppins", sans-serif;
    font-weight: 300;
    font-size: 12px !important;
  }

  .item-price {
    display: flex;
    justify-content: space-between;
    margin: 0 2rem 1rem;
    font-weight: 400;
    font-family: "Poppins", sans-serif;
    font-size: 12px;

  }

  .list-bullet {
    list-style: inside;
    text-align: left;
    margin: 2rem;
    line-height: 1.5;
    font-family: "Poppins", sans-serif;
    font-weight: 300;
    font-size: 9px;
  }
  
}












@media screen and (max-width: 480px) {
  .card-container {
    background-color: #efefef;
    border: 1.7px solid #f09f45;
    height: 475px;
    width: 280px;
    padding-bottom: 1rem;
  }

  .card-container h3 {
    font-size: 12px;
    margin-top: 2rem;
    font-family: "Aboreto", cursive;
  }

  .card-container p {
    text-decoration: underline;
    padding: 1rem 0 0;
    font-family: "Aboreto", cursive;
    font-size: 10px;
  }
  
  .menu-wrapper {
    display: flex;
    justify-content: space-between;
    margin: 0 2rem;
    align-items: center;
    list-style: none;
    line-height: 1.9;
    font-family: "Poppins", sans-serif;
    font-weight: 300;
    font-size: 9px !important;
  }

  .item-price {
    display: flex;
    justify-content: space-between;
    margin: 0 2rem 1rem;
    font-weight: 400;
    font-family: "Poppins", sans-serif;
    font-size: 9px;

  }

  .list-bullet {
    list-style: inside;
    text-align: left;
    margin: 2rem;
    line-height: 1.5;
    font-family: "Poppins", sans-serif;
    font-weight: 300;
    font-size: 9px;
  }
  
}
